import $ from 'jquery';
import anime from 'animejs';
import { Datepicker } from 'vanillajs-datepicker';
import { getElementOffset, isMobile, isVisible } from './helper';
import { OBSERVER } from '../plugins';
import { scrollToBlock } from './scrollToBlock';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: $('html')[0].lang,
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer-2"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer-2"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

// Fonction permettant de show/hide le retour d'informations
export function feedback(object) {
  const options = {};
  options.text = object.text;
  options.label = object.label;
  options.href = object.href;
  options.dataRequest = object.dataRequest;
  options.dataRequestData = object.dataRequestData === undefined ? '' : object.dataRequestData;
  options.dataRequestUpdate = object.dataRequestUpdate === undefined ? '' : object.dataRequestUpdate;
  options.dataRequestSuccess = object.dataRequestSuccess === undefined ? '' : object.dataRequestSuccess;

  const template = document.querySelector('.js-template-feedback');
  const cloneTemplate = template.content.cloneNode(true);
  const classActive = 'is-active';

  // Changer les contenus
  cloneTemplate.querySelector('.js-feedback-text').textContent = options.text;
  if (options.href) {
    cloneTemplate.querySelector('.js-feedback-link').textContent = options.label;
    cloneTemplate.querySelector('.js-feedback-link').href = options.href;
    cloneTemplate.querySelector('.js-feedback-button').remove();
  } else if (options.dataRequest) {
    cloneTemplate.querySelector('.js-feedback-button').textContent = options.label;
    cloneTemplate.querySelector('.js-feedback-button').setAttribute('data-request', options.dataRequest);
    cloneTemplate.querySelector('.js-feedback-button').setAttribute('data-request-data', options.dataRequestData);
    cloneTemplate.querySelector('.js-feedback-button').setAttribute('data-request-update', options.dataRequestUpdate);
    cloneTemplate.querySelector('.js-feedback-button').setAttribute('data-request-success', options.dataRequestSuccess);
    cloneTemplate.querySelector('.js-feedback-link').remove();
  } else {
    cloneTemplate.querySelector('.js-feedback-link').remove();
    cloneTemplate.querySelector('.js-feedback-button').remove();
  }

  // Ajouter le clone dans le DOM
  document.body.appendChild(cloneTemplate);

  // Constantes des retours d'informations
  const feedbacks = document.querySelectorAll('.js-feedback');
  const newFeedback = feedbacks[feedbacks.length - 1];

  // Show le nouveau retour d'informations
  setTimeout(() => { newFeedback.classList.add(classActive); }, 10);

  // Hide le nouveau retour d'informations
  setTimeout(() => {
    newFeedback.classList.remove(classActive);
    setTimeout(() => { newFeedback.remove(); }, 300 + 1);
  }, 5000);

  // Fermer tous les retours d'informations
  function closeAll() {
    feedbacks.forEach((el) => { el.classList.remove(classActive); });
  }

  OBSERVER.add({
    name: 'feedback',
    events: 'click',
    targets: '.js-feedback-link, .js-feedback-button, a[href]',
    function: closeAll,
  });
  OBSERVER.on('feedback');
}

// Fonction permettant de show/hide le tooltip du bouton information sur l'image dans une page de détail
export function tooltipPictureCredits() {
  const classActive = 'is-active';

  function onClick(e) {
    const tooltip = e.currentTarget.nextElementSibling;

    if (tooltip.classList.contains(classActive) && isMobile()) {
      tooltip.classList.remove(classActive);
    } else {
      tooltip.classList.add(classActive);
    }
  }

  function onMouseEnter(e) {
    if (!isMobile()) {
      e.currentTarget.nextElementSibling.classList.add(classActive);
    }
  }

  function onMouseLeave(e) {
    if (!isMobile()) {
      e.currentTarget.nextElementSibling.classList.remove(classActive);
    }
  }

  OBSERVER.add({
    name: 'tooltipPictureCredits',
    events: 'mouseenter',
    targets: '.js-picture-credits-button',
    function: onMouseEnter,
  });
  OBSERVER.add({
    name: 'tooltipPictureCredits',
    events: 'mouseleave',
    targets: '.js-picture-credits-button',
    function: onMouseLeave,
  });
  OBSERVER.add({
    name: 'tooltipPictureCredits',
    events: 'click',
    targets: '.js-picture-credits-button',
    function: onClick,
  });
  OBSERVER.on('tooltipPictureCredits');
}

// Fonction permettant de show/hide des liens rapides dans la page d'accueil
export function toggleVisibilityHomeFastLinks() {
  if (document.querySelectorAll('.js-home-fast-links-list-list-item').length) {
    const buttonContainer = document.querySelector('.js-home-fast-links-button-container');
    const button = document.querySelector('.js-home-fast-links-button');
    let countFastLinksStatic;
    let fastLinks;
    let fastLinksStatic;
    let fastLinksToggle;
    let isFastLinksToggle = false;

    const setVariables = () => {
      countFastLinksStatic = parseInt(getComputedStyle(document.querySelector(':root')).getPropertyValue('--home-fast-links-list-nb-items-visible'), 10);
      fastLinks = document.querySelectorAll('.js-home-fast-links-list-list-item');
      fastLinksStatic = Array.prototype.slice.call(fastLinks, 0, countFastLinksStatic);
      fastLinksToggle = Array.prototype.slice.call(fastLinks, countFastLinksStatic);
    };

    const onLoad = () => {
      if (buttonContainer !== null) {
        if (fastLinks.length > countFastLinksStatic) {
          buttonContainer.style.display = '';
        } else {
          buttonContainer.style.display = 'none';
        }
      }
    };

    const onResize = () => {
      fastLinksStatic.forEach((element) => { const el = element; el.style.display = 'flex'; el.style.opacity = '1'; });

      if (isFastLinksToggle) {
        fastLinksToggle.forEach((element) => { const el = element; el.style.display = 'flex'; el.style.opacity = '1'; });
      } else {
        fastLinksToggle.forEach((element) => { const el = element; el.style.display = 'none'; el.style.opacity = '0'; });
      }

      if (buttonContainer !== null) {
        if (fastLinks.length > countFastLinksStatic) {
          buttonContainer.style.display = '';
        } else {
          buttonContainer.style.display = 'none';
        }
      }
    };

    const toggleVisibility = () => {
      if (isFastLinksToggle) {
        anime({
          targets: '.js-home-fast-links-button',
          opacity: [1, 0],
          duration: 300,
          easing: 'linear',
          begin() {
            scrollToBlock({ scrollTo: '.js-section-home-fast-links' });
          },
          complete() {
            button.style.display = 'none';
            button.textContent = button.dataset.labelMore;
          },
        });

        for (let i = 0; i < fastLinksToggle.length; i += 1) {
          anime({
            targets: fastLinksToggle[i],
            opacity: [1, 0],
            duration: 300,
            easing: 'linear',
            // eslint-disable-next-line no-loop-func
            complete() {
              fastLinksToggle[i].style.display = 'none';
            },
          });
        }

        setTimeout(() => {
          anime({
            targets: '.js-home-fast-links-button',
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            begin() {
              button.style.display = '';
            },
          });
        }, 301);

        isFastLinksToggle = false;
      } else {
        for (let i = 0; i < fastLinksToggle.length; i += 1) {
          anime({
            targets: fastLinksToggle[i],
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            // eslint-disable-next-line no-loop-func
            begin() {
              fastLinksToggle[i].style.display = 'flex';
              button.textContent = button.dataset.labelLess;
            },
          });
        }

        isFastLinksToggle = true;
      }
    };

    OBSERVER.add({
      name: 'toggleVisibilityHomeFastLinks',
      events: 'load resize',
      function: setVariables,
    });
    OBSERVER.add({
      name: 'toggleVisibilityHomeFastLinks',
      events: 'load',
      function: onLoad,
    });
    OBSERVER.add({
      name: 'toggleVisibilityHomeFastLinks',
      events: 'resize',
      function: onResize,
    });
    OBSERVER.add({
      name: 'toggleVisibilityHomeFastLinks',
      events: 'click',
      targets: '.js-home-fast-links-button',
      function: toggleVisibility,
    });
    OBSERVER.on('toggleVisibilityHomeFastLinks');
  }
}

// Fonction permettant d'afficher/disparaitre les boutons sticky à droite en haut de la fenêtre en mobile (page event)
export function showHideStickyTopRightEventAnchorLinks() {
  if (document.querySelector('.js-sticky-top-right-event-anchor-links-container')) {
    const onScroll = () => {
      const stickyTopRightEventAnchorLinksContainer = document.querySelector('.js-sticky-top-right-event-anchor-links-container');
      const eventAnchorLinks = document.querySelector('.js-event-anchor-links');
      const sectionPaginationDetail = document.querySelector('.js-section-pagination-detail');

      // Toggle visibility sticky top right event anchor links container (en haut de page et bas de page)
      // eslint-disable-next-line max-len
      if (document.documentElement.scrollTop < getElementOffset({ element: eventAnchorLinks }).top + eventAnchorLinks.clientHeight + 30 || document.documentElement.scrollTop >= getElementOffset({ element: sectionPaginationDetail }).top + sectionPaginationDetail.clientHeight - 30) {
        stickyTopRightEventAnchorLinksContainer.classList.add('is-hidden');
      } else {
        stickyTopRightEventAnchorLinksContainer.classList.remove('is-hidden');
      }
    };

    onScroll();
    OBSERVER.add({
      name: 'showHideStickyTopRightEventAnchorLinks',
      events: 'scroll',
      targets: 'window',
      function: onScroll,
    });
    OBSERVER.on('showHideStickyTopRightEventAnchorLinks');
  }
}

// Fonction permettant d'afficher/disparaitre les boutons sticky à gauche de la fenêtre en desktop
export function showHideStickyBottomDesktop() {
  if (document.querySelector('.js-sticky-bottom-desktop-container')) {
    const onScroll = () => {
      const stickyBottomDesktopContainer = document.querySelector('.js-sticky-bottom-desktop-container');
      const buttonBackToTopContainer = document.querySelector('.js-sticky-bottom-desktop-button-back-to-top-container');
      const buttonMyFavoritesContainer = document.querySelector('.js-sticky-bottom-desktop-button-my-favorites-container');
      const footer = document.querySelector('.js-footer');

      // Toggle visibility button back to top
      if (document.documentElement.scrollTop <= 600) {
        buttonBackToTopContainer.classList.add('is-hidden');

        if (document.querySelector('.js-sticky-bottom-desktop-container').classList.contains('js-sticky-bottom-desktop-container-in-page-home')) {
          buttonMyFavoritesContainer.classList.add('is-hidden');
        }
      } else {
        buttonBackToTopContainer.classList.remove('is-hidden');

        if (document.querySelector('.js-sticky-bottom-desktop-container').classList.contains('js-sticky-bottom-desktop-container-in-page-home')) {
          buttonMyFavoritesContainer.classList.remove('is-hidden');
        }
      }

      // Toggle visibility sticky bottom desktop container
      if (isVisible(footer, { top: -80 })) {
        stickyBottomDesktopContainer.classList.add('is-hidden');
      } else {
        stickyBottomDesktopContainer.classList.remove('is-hidden');
      }
    };

    onScroll();
    OBSERVER.add({
      name: 'showHideStickyBottomDesktop',
      events: 'scroll',
      targets: 'window',
      function: onScroll,
    });
    OBSERVER.on('showHideStickyBottomDesktop');
  }
}

// Fonction permettant d'afficher/disparaitre la barre grise qui contient des liens d'ancrages dans la page détail d'un événement
export function showHideStickyEventAnchorLinks() {
  if (document.querySelector('.js-event-anchor-links')) {
    const onScroll = () => {
      const stickyEventAnchorLinks = document.querySelector('.js-event-anchor-links');
      const sectionAddToCalendarOptions = document.querySelector('.js-section-add-to-calendar-options');

      // Toggle visibility sticky event anchor links
      if (document.documentElement.scrollTop >= getElementOffset({ element: sectionAddToCalendarOptions }).top + sectionAddToCalendarOptions.clientHeight - 30) {
        stickyEventAnchorLinks.classList.add('is-hidden');
      } else {
        stickyEventAnchorLinks.classList.remove('is-hidden');
      }
    };

    onScroll();
    OBSERVER.add({
      name: 'showHideStickyEventAnchorLinks',
      events: 'scroll',
      targets: 'window',
      function: onScroll,
    });
    OBSERVER.on('showHideStickyEventAnchorLinks');
  }
}

export function listLoaded(data) {
  if (!data.hasMorePages) {
    $('#listMoreBtn').hide();
  } else {
    $('#listMoreBtn').show();
  }
}

export function activeFilter(element) {
  $('.c-filter-swiper__option').removeClass('c-filter-swiper__option--active');
  $(element).addClass('c-filter-swiper__option--active');
}

export function initSopfeuWidgets() {
  $.request('sopfeuwidget::onInitWidget', {
    update: { 'sopfeuwidget::footer': '#sopfeuFooterWrapper', 'sopfeuwidget::banner': '#sopfeuBannerWrapper' },
  });
}
