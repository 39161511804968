import { OBSERVER } from '../plugins';

// Retourne si le site est ouvert dans un appareil mobile
export function isMobile() {
  let condition = false;

  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    condition = true;
  }

  return condition;
}

// Retourne le offset d'un élément dans la page
export function getElementOffset(options) {
  let box;

  if (typeof options.element === 'string') {
    box = document.querySelector(options.element).getBoundingClientRect();
  } else {
    box = options.element.getBoundingClientRect();
  }

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

// Retourne le poids d'un fichier
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 ko';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// Retourne si un élément est visible dans le viewport
export function isVisible(el, object = {}) {
  const rect = el.getBoundingClientRect();
  const offsets = {
    bottom: object.bottom || 0,
    right: object.right || 0,
    top: object.top || 0,
    left: object.left || 0,
  };

  const bottom = rect.bottom + offsets.bottom >= 0;
  const right = rect.right + offsets.right >= 0;
  const top = rect.top + offsets.top <= (window.innerHeight || document.documentElement.clientHeight);
  const left = rect.left + offsets.left <= (window.innerWidth || document.documentElement.clientWidth);

  return bottom && right && top && left;
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      let k;
      const { styleSheets } = document;
      const styleSheetsLength = styleSheets.length;
      for (k = 0; k < styleSheetsLength; k += 1) {
        // for (const si in document.styleSheets) {
        const styleSheet = styleSheets[k];
        if (styleSheet.rules) {
          for (let ri = styleSheet.rules.length - 1; ri >= 0; ri -= 1) {
            if (styleSheet.rules[ri].selectorText) {
              if (styleSheet.rules[ri].selectorText.match(':hover')) {
                const { selectorText } = styleSheet.rules[ri];
                const araySelectorText = selectorText.split(',');
                let newSelectorText = '';

                // Garder slm les selectorText no :hover
                for (let i = 0; i < araySelectorText.length; i += 1) {
                  if (!araySelectorText[i].match(':hover')) {
                    newSelectorText += newSelectorText !== '' ? `, ${araySelectorText[i]}` : ` ${araySelectorText[i]}`;
                  }
                }

                // Enlver la règle si la seule classe est un :hover
                if (newSelectorText === '') {
                  styleSheet.deleteRule(ri);
                } else {
                  styleSheet.rules[ri].selectorText = newSelectorText;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

// Réduit la taille des H1 s'il comporte trop de caractères
// Utilisation: data-font-size-based-on-caracters-count="{font-size}:min-max,{font-size}:min-max"
export function fontSizeBasedOnCaracters() {
  const elements = document.querySelectorAll('[data-font-size-based-on-caracters-count]');
  const elementsLength = elements.length;

  let i;
  let j;

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i];
    const elementTextLength = element.textContent.length;
    const fontSizesDataAttributeValue = element.getAttribute('data-font-size-based-on-caracters-count');
    const fontSizes = fontSizesDataAttributeValue.split(',');
    const fontSizesLength = fontSizes.length;

    for (j = 0; j < fontSizesLength; j += 1) {
      const fontSizeAndCount = fontSizes[j];
      const fontSizeAndCountArray = fontSizeAndCount.split(':');

      const fontSize = fontSizeAndCountArray[0];

      const countMinAndMax = fontSizeAndCountArray[1];
      const countMinAndMaxArray = countMinAndMax.split('-');
      const min = countMinAndMaxArray[0];
      const max = countMinAndMaxArray[1];

      if (elementTextLength >= min && elementTextLength <= max) {
        element.classList.add(`u-font-size-${fontSize}`);
      }
    }
  }
}

// Permet de donner la hauteur exact en mobile de 100vh
// Css -> height: calc(var(--vh, 1vh) * 100);
export function hundredVH() {
  function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  OBSERVER.add({
    name: 'hundredVH',
    events: 'resize load',
    targets: 'window',
    function: setHeight,
  });
  OBSERVER.on('hundredVH');
}

// Permet de donner la largeur exact en mobile de 100vw
// Css -> width: calc(var(--vw, 1vw) * 100);
export function hundredVW() {
  function setWidth() {
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }

  OBSERVER.add({
    name: 'hundredVW',
    events: 'resize load',
    targets: 'window',
    function: setWidth,
  });
  OBSERVER.on('hundredVW');
}
