import anime from 'animejs';
import { getElementOffset } from './helper';
import { OBSERVER } from '../plugins';

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (object = {}) => {
  const options = {};
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const wd = window.document;
  const scrollbar = wd.scrollingElement || wd.body || wd.documentElement;
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo;

  const animation = anime
    .timeline({
      targets: scrollbar,
      duration: options.duration,
      easing: options.easing,
    })
    .add({ scrollTop: getElementOffset({ element }).top + options.offset });

  return animation.finished;
};

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (object) => {
  const options = {};
  options.selector = object.selector;
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  });

  OBSERVER.add({
    name: 'scrollToBlock',
    events: 'click',
    targets: options.selector,
    function: onClick,
  });

  OBSERVER.on('scrollToBlock');
};

// Permet d'utiliser la fonction scrollToBlock de facon dynamic avec des attributs datas
// Utilisation: data-scroll-button="section", data-scroll-destination="section", data-scroll-offset="value", data-scroll-offset-based-on="property" data-scroll-offset-based-on-variable-css="--var-css"
// Exemple: data-scroll-button="section", data-scroll-destination="section", data-scroll-offset="-50"
// Exemple: data-scroll-button="section", data-scroll-destination="section", data-scroll-offset-based-on="margin"
// Exemple: data-scroll-button="section", data-scroll-destination="section", data-scroll-offset-based-on-variable-css="--var-css"
export function dynamicScrollToBlock() {
  const scrollButtonsSelector = 'data-scroll-button';
  const scrollButtons = document.querySelectorAll(`[${scrollButtonsSelector}]`);
  const scrollButtonsLength = scrollButtons.length;
  const scrollDestinationsSelector = 'data-scroll-destination';
  const scrollDestinations = document.querySelectorAll(`[${scrollDestinationsSelector}]`);
  const scrollDestinationsLength = scrollDestinations.length;

  function setDynamicScrollToBlock() {
    for (let i = 0; i < scrollButtonsLength; i += 1) {
      const scrollFromDatasetValue = scrollButtons[i].dataset.scrollButton;

      for (let j = 0; j < scrollDestinationsLength; j += 1) {
        const scrollToDatasetValue = scrollDestinations[j].dataset.scrollDestination;

        if (scrollFromDatasetValue === scrollToDatasetValue) {
          const scrollOffsetDatasetValue = parseInt(scrollDestinations[j].dataset.scrollOffset, 10) || 0;
          const scrollOffsetType = scrollDestinations[j].dataset.scrollOffsetBasedOn || undefined;
          const scrollOffsetVariableCss = scrollDestinations[j].dataset.scrollOffsetBasedOnVariableCss || undefined;
          let scrollOffsetTypeValue = 0;
          let scrollOffsetVariableCssValue = 0;

          if (scrollOffsetType === 'margin') {
            const { marginTop } = window.getComputedStyle(scrollDestinations[j]);
            scrollOffsetTypeValue = -parseInt(marginTop, 10);
          }

          if (scrollOffsetVariableCss && /^--/.test(scrollOffsetVariableCss) && getComputedStyle(document.documentElement).getPropertyValue(scrollOffsetVariableCss)) {
            const marginTop = getComputedStyle(document.documentElement).getPropertyValue(scrollOffsetVariableCss).replace('px', '');
            scrollOffsetVariableCssValue = -parseInt(marginTop, 10);
          }

          clickToScrollToBlock({
            selector: `[${scrollButtonsSelector}="${scrollToDatasetValue}"]`,
            scrollTo: `[${scrollDestinationsSelector}="${scrollFromDatasetValue}"]`,
            offset: scrollOffsetDatasetValue + scrollOffsetTypeValue + scrollOffsetVariableCssValue,
          });
        }
      }
    }
  }

  setDynamicScrollToBlock();
  OBSERVER.add({
    name: 'dynamicScrollToBlock',
    events: 'resize',
    function: setDynamicScrollToBlock,
  });
  OBSERVER.on('dynamicScrollToBlock');
}
