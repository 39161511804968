// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';

// Swiper des alertes
export function swiperAlerts() {
  if (document.querySelector('[data-swiper="alerts"]')) {
    window.swiperAlerts = new Swiper('[data-swiper="alerts"]', {
      slidesPerView: 'auto',
      loop: (document.querySelectorAll('[data-swiper="alerts"] .swiper-slide').length > 1),
      threshold: 10,
      speed: 700,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: true,
      pagination: {
        el: '[data-swiper-pagination="alerts"]',
        type: 'fraction',
      },
      navigation: {
        prevEl: '[data-swiper-prev="alerts"]',
        nextEl: '[data-swiper-next="alerts"]',
      },
    });

    const onClose = () => {
      document.documentElement.style.setProperty('--alerts-height', '0px');
      OBSERVER.off('swiperAlerts');
    };

    const setHeight = () => {
      if (window.matchMedia('(max-width: 600px)').matches) {
        document.documentElement.style.setProperty('--alerts-height', '45px');
      } else {
        document.documentElement.style.setProperty('--alerts-height', '56px');
      }
    };

    OBSERVER.add({
      name: 'swiperAlerts',
      events: 'click',
      function: onClose,
      targets: '.js-close-alerts',
    });
    OBSERVER.add({
      name: 'swiperAlerts',
      events: 'resize load',
      function: setHeight,
    });
    OBSERVER.on('swiperAlerts');
  }
}

// Swiper de la bannière dans la page d'accueil
export function swiperHomeBanner() {
  if (document.querySelector('[data-swiper="home-banner"]')) {
    const language = document.querySelector('html').getAttribute('lang');

    window.swiperHomeBanner = new Swiper('[data-swiper="home-banner"]', {
      slidesPerView: 'auto',
      loop: (document.querySelectorAll('[data-swiper="home-banner"] .swiper-slide').length > 1),
      threshold: 10,
      speed: 700,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '[data-swiper-pagination="home-banner"]',
        type: 'bullets',
        clickable: true,
      },
      a11y: {
        paginationBulletMessage: (language === 'fr') ? 'Aller vers la bannière {{index}}' : 'Go to banner {{index}}',
      },
    });
  }
}

// Swiper des pages en vedettes
export function swiperFeaturedPages() {
  if (document.querySelector('[data-swiper="featured-pages"]')) {
    window.swiperFeaturedPages = new Swiper('[data-swiper="featured-pages"]', {
      slidesPerView: 1,
      spaceBetween: 26,
      threshold: 10,
      speed: 700,
      freeMode: false,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      pagination: {
        el: '[data-swiper-pagination="featured-pages"]',
        type: 'fraction',
      },
      navigation: {
        prevEl: '[data-swiper-prev="featured-pages"]',
        nextEl: '[data-swiper-next="featured-pages"]',
        disabledClass: 'is-disabled',
      },
      breakpoints: {
        601: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        1001: {
          slidesPerView: 1,
        },
      },
      on: {
        init(swiper) {
          if (swiper.isBeginning && swiper.isEnd && document.querySelector('.js-featured-pages-footer')) {
            document.querySelector('.js-featured-pages-footer').style.display = 'none';
          }
        },
        resize(swiper) {
          if (document.querySelector(swiper.params.el) && document.querySelector('.js-featured-pages-footer')) {
            if (swiper.isBeginning && swiper.isEnd) {
              document.querySelector('.js-featured-pages-footer').style.display = 'none';
            } else {
              document.querySelector('.js-featured-pages-footer').style.display = 'flex';
            }
          }
        },
      },
    });
  }
}

// Swiper des publications en vedette
export function swiperPublications() {
  if (document.querySelector('[data-swiper="publications"]')) {
    window.swiperPublications = new Swiper('[data-swiper="publications"]', {
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      speed: 700,
      freeMode: false,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: '[data-swiper-prev="publications"]',
        nextEl: '[data-swiper-next="publications"]',
        disabledClass: 'is-disabled',
      },
      breakpoints: {
        601: {
          slidesPerView: 2,
          spaceBetween: 35,
        },
        801: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1001: {
          slidesPerView: 2,
          spaceBetween: 60,
        },
        1201: {
          slidesPerView: 2,
          spaceBetween: 74,
        },
      },
    });
  }
}

// Swiper des événement en vedette
export function swiperEvents() {
  if (document.querySelector('[data-swiper="events"]')) {
    window.swiperEvents = new Swiper('[data-swiper="events"]', {
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      speed: 700,
      freeMode: false,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: '[data-swiper-prev="events"]',
        nextEl: '[data-swiper-next="events"]',
        disabledClass: 'is-disabled',
      },
      breakpoints: {
        601: {
          slidesPerView: 2,
          spaceBetween: 35,
        },
        801: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1001: {
          slidesPerView: 2,
          spaceBetween: 60,
        },
        1201: {
          slidesPerView: 2,
          spaceBetween: 74,
        },
      },
    });
  }
}

// Swiper d'un filtre
export function swiperFilter() {
  if (document.querySelector('[data-swiper="filter"]')) {
    window.swiperFilter = new Swiper('[data-swiper="filter"]', {
      slidesPerView: 'auto',
      spaceBetween: 6,
      threshold: 10,
      speed: 300,
      freeMode: true,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: '[data-swiper-prev="filter"]',
        nextEl: '[data-swiper-next="filter"]',
        disabledClass: 'is-disabled',
      },
    });
  }
}

// Swiper de la bannière dans la page promo
export function swiperPromoBannerImages() {
  const swiper = document.querySelector('[data-swiper="promo-banner-images"]');
  if (swiper) {
    window.swiperPromoBannerImages = new Swiper(swiper, {
      slidesPerView: 1,
      loop: true,
      speed: 700,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '[data-swiper-pagination="promo-banner-images"]',
        clickable: true,
        renderBullet: function (index, className) {
          return '<div id="' + index + '" class="' + className + '"><picture><img class="pagination-bullet || cover || lazy"/></picture></div>';
        },
      },
    });

    const addUrlToPaginationImage = () => {
      const bullets = document.querySelectorAll('.swiper-pagination-bullet');

      bullets.forEach((bullet) => {
        const bulletId = bullet.id;
        const bulletImage = bullet.querySelector('.pagination-bullet');

        const swiperImage = swiper.querySelector(`[data-swiper-slide-index="${bulletId}"] .c-promo-banner__img`);
        const swiperImageSrc = swiperImage.src;
        const swiperImageDataSrc = swiperImage.dataset.src;

        bulletImage.setAttribute('src', `${swiperImageSrc}`);
        bulletImage.setAttribute('data-src', `${swiperImageDataSrc}`);
      });
    };

    addUrlToPaginationImage();
  }
}

// Swiper de la page promo
export function swiperPromo() {
  if (document.querySelector('[data-swiper="promo"]')) {
    const screenWidth = window.innerWidth;
    const slides = document.querySelectorAll('[data-swiper="promo"] .swiper-slide');
    const slidesLength = slides.length;

    let swiperSlidesPerView = 3.2;

    if (screenWidth > 1600) {
      swiperSlidesPerView = 3.2;
    } else if (screenWidth > 1100) {
      swiperSlidesPerView = 2.2;
    } else if (screenWidth > 600) {
      swiperSlidesPerView = 1.2;
    } else {
      swiperSlidesPerView = 1.1;
    }

    const swiperLoop = slidesLength > swiperSlidesPerView;

    window.swiperPromo = new Swiper('[data-swiper="promo"]', {
      slidesPerView: swiperSlidesPerView,
      spaceBetween: 15,
      speed: 700,
      loop: swiperLoop,
      breakpoints: {
        1100: {
          spaceBetween: 65,
        },
        600: {
          spaceBetween: 40,
        },
      },
    });

    const toggleInfosPanel = (e) => {
      e.preventDefault();

      const button = e.currentTarget;
      const container = button.parentNode;
      const panelScroll = container.querySelector('[data-js="panel-scroll"]');

      panelScroll.scrollTop = '0';
      container.classList.toggle('active');
    };

    const hideInfosPanel = () => {
      const activeContainers = document.querySelectorAll('[data-swiper="promo"] .active');
      activeContainers.forEach((activeContainer) => {
        activeContainer.classList.remove('active');
      });
    };

    window.swiperPromo.on('slideChange', hideInfosPanel);

    OBSERVER.add({
      name: 'swiperPromo',
      events: 'click',
      function: toggleInfosPanel,
      targets: '[data-js="toggle-side-panel-button"]',
    });
    OBSERVER.on('swiperPromo');
  }
}
