import Accordions from '../classes/accordions';
import FractionCircle from '../classes/fractionCircle';

export default function promoRubrics() {
  const promoRubricsContainers = document.querySelectorAll('[data-desc-card-container]');

  const promoRubric = new Accordions({
    offset: 0,
    scrollToAccordion: false,
  });
  promoRubric.init();

  promoRubricsContainers.forEach((container) => {
    const promoRubricFraction = new FractionCircle(container);
    promoRubricFraction.init();
  });
}
