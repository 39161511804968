import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { SCROLLFIRE, OBSERVER } from './plugins';
import fadeTransition from './transitions/fade-transition';
import {
  backendKeyBind,
  ogContent,
  feedback,
  toggleVisibilityHomeFastLinks,
  tooltipPictureCredits,
  showHideStickyBottomDesktop,
  showHideStickyTopRightEventAnchorLinks,
  showHideStickyEventAnchorLinks,
  listLoaded,
  activeFilter,
  initSopfeuWidgets,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import { resetDropzone, formsPackage } from './functions/form';
import {
  overlayShare, overlayCalendar, overlayMenuDesktop, overlayMenuMobile, overlaySearch, overlayPopup, overlayAnchors,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formExample, formSuccess, formContact, formJobs, formEventRegistration, formNewsletter,
} from './functions/validation';
import masksPackage from './functions/masks';
import {
  removeHoverOnMobile, hundredVH, hundredVW, fontSizeBasedOnCaracters,
} from './functions/helper';
import {
  swiperAlerts, swiperHomeBanner, swiperFeaturedPages, swiperFilter, swiperPublications, swiperEvents,
  swiperPromoBannerImages,
  swiperPromo,
} from './functions/sliders';
import { dynamicScrollToBlock } from './functions/scrollToBlock';
import FieldGroup from './classes/fieldGroup';
import tippyAddToFavorites from './functions/tooltip';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';
import promoRubrics from './functions/promoRubrics';
/* global initFormBuilder */

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.feedback = feedback;
window.listLoaded = listLoaded;
window.activeFilter = activeFilter;
window.SCROLLFIRE = SCROLLFIRE;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    tippyAddToFavorites();
    showHideStickyBottomDesktop();
    swiperAlerts();
    overlayShare();
    overlayMenuDesktop();
    overlayMenuMobile();
    overlaySearch();
    overlayPopup();
    backendKeyBind();
    ShareButtons.update();
    SCROLLFIRE.init();
    dynamicScrollToBlock();
    fontSizeBasedOnCaracters();
    removeHoverOnMobile();
    hundredVH();
    hundredVW();
    initSopfeuWidgets();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.after(() => {
    fbq('track', '219182986606675', 'PageView')
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => (el.classList && el.classList.contains('js-barba-prevent')) || (el.getAttribute('href') && el.getAttribute('href').indexOf('#') > -1),

    transitions: [fadeTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          tooltipPictureCredits();
          rubrics();
          masksPackage();
          dynamicPackage();
          formExample();
          swiperPublications();
          swiperEvents();
          overlayAnchors();
          window.lazyload = new LazyLoad();

          initFormBuilder();
          if (document.querySelector('#form-example')) {
            formsPackage();
          }
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          tooltipPictureCredits();
          swiperHomeBanner();
          swiperFeaturedPages();
          toggleVisibilityHomeFastLinks();
          swiperPublications();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationsView',
        beforeEnter() {
          swiperFilter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationView',
        beforeEnter() {
          tooltipPictureCredits();
          dynamicPackage();
          swiperPublications();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsView',
        beforeEnter() {
          swiperFilter();
          overlayCalendar();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventView',
        beforeEnter() {
          showHideStickyTopRightEventAnchorLinks();
          showHideStickyEventAnchorLinks();
          tooltipPictureCredits();
          dynamicPackage();
          formEventRegistration();
          FieldGroup.init();
          swiperEvents();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          tooltipPictureCredits();
          masksPackage();
          dynamicPackage();
          formsPackage();
          formJobs();
          overlayAnchors();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobView',
        beforeEnter() {
          tooltipPictureCredits();
          masksPackage();
          dynamicPackage();
          rubrics();
          formsPackage();
          formJobs();
          overlayAnchors();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'myFavoritesView',
        beforeEnter() {
          swiperFilter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'sectionView',
        beforeEnter() {
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          swiperFilter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          tooltipPictureCredits();
          masksPackage();
          formsPackage();
          formContact();
          rubrics();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          masksPackage();
          dynamicPackage();
          formNewsletter();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'promoView',
        beforeEnter() {
          swiperPromoBannerImages();
          swiperPromo();
          dynamicPackage();
          promoRubrics();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
