export default class FractionCircle {
  constructor(container) {
    this.container = container;

    // Event handlers (bound to this instance)
    this.boundedSetCurrentNumber = () => this.setCurrentNumber();
  }

  init() {
    this.current = this.container.querySelector('[data-current-desc]');
    this.elements = this.container.querySelectorAll('[data-desc-card]');

    const totalElement = this.container.querySelector('[data-total-desc]');
    const elementsLength = this.elements.length;

    totalElement.textContent = elementsLength;

    this.setNumber();

    window.addEventListener('scroll', this.boundedSetCurrentNumber);
    window.addEventListener('accordionOpenComplete', this.boundedSetCurrentNumber);
  }

  setNumber(number = 1) {
    this.currentNumber = number;
    this.current.textContent = this.currentNumber;
  }

  setCurrentNumber() {
    const scrollOffset = window.scrollY;

    this.elements.forEach((element, index) => {
      const elementRect = element.getBoundingClientRect();
      const elementOffset = elementRect.top + window.scrollY - 10;

      if (scrollOffset > elementOffset) {
        this.setNumber(index + 1);
      }
    });
  }

  destroy() {
    window.removeEventListener('scroll', this.boundedSetCurrentNumber);
    window.removeEventListener('accordionOpenComplete', this.boundedSetCurrentNumber);
  }
}
