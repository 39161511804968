// Documentation: https://atomiks.github.io/tippyjs
import tippy from 'tippy.js';

export default function tippyAddToFavorites() {
  if (document.querySelector('[data-tippy="add-to-favorites"]')) {
    window.tippyAddToFavorites = tippy('[data-tippy="add-to-favorites"]', {
      theme: 'add-to-favorites',
      onTrigger(instance) {
        instance.setContent(instance.reference.dataset.tippyContent);
      },
    });
  }
}
