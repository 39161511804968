import $ from 'jquery';
import anime from 'animejs';
import { formsPackage } from '../functions/form';
import masksPackage from '../functions/masks';
import { scrollToBlock } from '../functions/scrollToBlock';
import { OBSERVER } from '../plugins';

/*
LITES DES DATAS:
- data-field-group-form
- data-field-group-template
- data-field-group-container
- data-field-group
- data-field-group-title (optionnel)
- data-field-group-add
- data-field-group-delete

LISTE DES FONCTIONS:
- init()
- addTemplate(template, name, animate)
- delete(e, name)
- updateIndex(name)
- setTitle(fieldGroup, index)
- setID(fieldGroup, index, name)
- setFor(fieldGroup, index, name)
- setName(fieldGroup, index, name)
- setValidation(fieldGroup, index, name)

À SAVOIR:
- la constante ELEMENTS équivaut à un bloc de champ dans un formulaire
- la constance INSTANCES équivaut aux boutons deletes de chacun des blocs de formulaire

LISTE DES AJOUT/RETRAIT:
- retrait de la fonction setName(...) sous forme de commentaire
- ajout de la fonction setValidation(...)
*/

export default class FieldGroup {
  static init() {
    const TEMPLATES = document.querySelectorAll('[data-field-group-template]');

    TEMPLATES.forEach((template) => {
      const NAME = template.getAttribute('data-field-group-template');
      const CONTAINER = document.querySelector(`[data-field-group-container="${NAME}"]`);
      const ELEMENTS = CONTAINER.querySelectorAll(`[data-field-group="${NAME}"]`);
      const ELEMENTS_LENGTH = ELEMENTS.length;

      if (ELEMENTS_LENGTH === 0) {
        FieldGroup.addTemplate(template, NAME, false);
      }

      OBSERVER.add({
        name: 'FieldGroupAdd',
        events: 'click',
        targets: `[data-field-group-add="${NAME}"]`,
        function: () => { FieldGroup.addTemplate(template, NAME); },
      });
      OBSERVER.on('FieldGroupAdd');
    });
  }

  // Ajout d'un bloc de champs au formulaire
  static addTemplate(template, name, animate = true) {
    const CLONE = template.content.cloneNode(true);
    const FIRST_CLONE_ELEMENT = CLONE.querySelector(`[data-field-group="${name}"]`);
    if (animate) {
      FIRST_CLONE_ELEMENT.classList.add('is-hidden');
    }

    const CONTAINER = document.querySelector(`[data-field-group-container="${name}"]`);
    const ELEMENTS = CONTAINER.querySelectorAll(`[data-field-group="${name}"]`);
    const INDEX = ELEMENTS.length;

    FieldGroup.setTitle(CLONE, INDEX);
    FieldGroup.setID(CLONE, INDEX, name);
    FieldGroup.setFor(CLONE, INDEX, name);
    /* FieldGroup.setName(CLONE, INDEX, name); */
    FieldGroup.setValidation(CLONE, INDEX, name);

    document.querySelector(`[data-field-group-container="${name}"]`).appendChild(CLONE);

    // Gérer l'affichage du bouton delete
    const INSTANCES = document.querySelectorAll(`[data-field-group-delete="${name}"]`);
    const INSTANCES_LENGTH = INSTANCES.length;
    if (INSTANCES_LENGTH > 1) {
      INSTANCES.forEach((instance) => {
        instance.classList.remove('is-hidden');
      });
    }

    formsPackage();
    masksPackage();

    if (animate) {
      const HEIGHT = FIRST_CLONE_ELEMENT.children[0].offsetHeight;
      anime({
        targets: FIRST_CLONE_ELEMENT,
        height: HEIGHT,
        easing: 'easeInOutCubic',
        duration: 500,
        complete: () => {
          FIRST_CLONE_ELEMENT.style.height = '';
        },
      });
      FIRST_CLONE_ELEMENT.classList.remove('is-hidden');
    }

    FieldGroup.addEventOnDeleteButton(name);
  }

  // Ajout de l'événement clic au nouveau bouton delete
  static addEventOnDeleteButton(name) {
    OBSERVER.off('FieldGroupDelete');
    OBSERVER.add({
      name: 'FieldGroupDelete',
      events: 'click',
      targets: `[data-field-group-delete="${name}"]`,
      function: (e) => { FieldGroup.delete(e, name); },
    });
    OBSERVER.on('FieldGroupDelete');
  }

  // Supprime un bloc de champs au formulaire
  static delete(e, name) {
    const ELEMENT = e.currentTarget.closest(`[data-field-group="${name}"]`);
    ELEMENT.style.height = `${ELEMENT.children[0].offsetHeight}px`;

    // Gérer l'affichage du bouton delete du premier bloc pour ne pas le voir
    const INSTANCES = document.querySelectorAll(`[data-field-group-delete="${name}"]`);
    const INSTANCES_LENGTH = INSTANCES.length;
    if (INSTANCES_LENGTH - 1 <= 1) {
      INSTANCES.forEach((instance) => {
        instance.classList.add('is-hidden');
      });
    }

    anime({
      targets: ELEMENT,
      height: '0px',
      easing: 'easeInOutCubic',
      duration: 500,
      complete: () => {
        ELEMENT.remove();
        FieldGroup.updateIndex(name);
      },
    });
    ELEMENT.classList.add('is-hidden');

    // Scroller vers le bloc du formulaire qui précède celui qu'on vient de supprimer
    if (ELEMENT.previousElementSibling) {
      scrollToBlock({
        scrollTo: ELEMENT.previousElementSibling,
        easing: 'easeInOutCubic',
        duration: 500,
        offset: ELEMENT.previousElementSibling.offsetHeight - window.innerHeight,
      });
    }
  }

  // Update les attributs des champs du formulaire quand il y a l'ajout/retrait d'un bloc de champs du formulaire
  static updateIndex(name) {
    const ELEMENTS = document.querySelectorAll(`[data-field-group="${name}"]`);

    ELEMENTS.forEach((element, index) => {
      FieldGroup.setTitle(element, index);
      FieldGroup.setID(element, index, name);
      FieldGroup.setFor(element, index, name);
      /* FieldGroup.setName(element, index, name); */
      FieldGroup.setValidation(element, index, name);
    });
  }

  // Setup ou update le titre pour qu'il ressemble à: logement #1
  static setTitle(fieldGroup, index) {
    const SEPARATOR = '#';
    const SELECTOR = '[data-field-group-title]';
    const ELEMENT = fieldGroup.querySelector(SELECTOR);

    if (typeof ELEMENT !== 'undefined' && ELEMENT !== null) {
      const CONTENT_WITHOUT_SEPARATOR = ELEMENT.textContent.split(SEPARATOR)[0];
      ELEMENT.textContent = `${CONTENT_WITHOUT_SEPARATOR}${SEPARATOR}${index + 1}`;
    }
  }

  // Setup ou update le ID pour qu'il ressemble à: form-step-1-first-name--adult-1
  static setID(fieldGroup, index, name) {
    const SEPARATOR = `--${name}-`;
    const SELECTOR = `[id*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);

    ELEMENTS.forEach((elementWithID) => {
      const REGEX_RULE = `--${name}-([0-9]{1,}|)--`;
      const ID = elementWithID.id.replace(new RegExp(REGEX_RULE, 'g'), `--${name}-${index + 1}--`);
      elementWithID.setAttribute('id', ID);
    });
  }

  // Setup ou update le for pour qu'il ressemble à: form-step-1-first-name--adult-1
  static setFor(fieldGroup, index, name) {
    const SEPARATOR = `--${name}-`;
    const SELECTOR = `[for*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);

    ELEMENTS.forEach((elementWithFor) => {
      const REGEX_RULE = `--${name}-([0-9]{1,}|)--`;
      const FOR = elementWithFor.getAttribute('for').replace(new RegExp(REGEX_RULE, 'g'), `--${name}-${index + 1}--`);
      elementWithFor.setAttribute('for', FOR);
    });
  }

  // Setup ou update le name pour qu'il ressemble à: form-step-1-first-name--adult-[1]
  /* static setName(fieldGroup, index, name) {
    const SEPARATOR = `--${name}-`;
    const SELECTOR = `[name*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);
    ELEMENTS.forEach((elementWithName) => {
      const NAME = elementWithName.getAttribute('name').replace(/\[.*\]/, `[${index + 1}]`);
      elementWithName.setAttribute('name', NAME);
    });
  } */

  // Setup ou update la validation de chaque champ du formulaire
  static setValidation(fieldGroup, index, name) {
    const FORM = document.querySelector(`[data-field-group-form="${name}"]`);
    const ELEMENTS = fieldGroup.querySelectorAll('input');

    ELEMENTS.forEach((element) => {
      const NAME_FIELD = element.name;
      const ID_FIELD = element.id;

      // On récupère les règles et les messages pour chaque champ et on lui redonne cette même règle
      const RULES = $(FORM).validate().settings.rules[NAME_FIELD];
      const COPY_RULES = $.extend(true, {}, RULES);
      const MESSAGES = $(FORM).validate().settings.messages[NAME_FIELD];
      const RULES_AND_MESSAGES = Object.assign(COPY_RULES, { messages: MESSAGES });

      // On change l'attribut aria-describedby pour chaque champ
      element.setAttribute('aria-describedby', `${ID_FIELD}-error`);

      // Ajoute les règles de validations et les messages d'erreurs
      $(element).rules('add', RULES_AND_MESSAGES);
    });
  }
}
